<!--
File: InventoryTypeEditForm.vue
Description: form for adding/editing a single inventory type.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
        <div class="card-icon"> <md-icon>perm_identity</md-icon> </div>
        <h4 class="title"> {{ $t(`route.inventory_type_${oper}`) }} </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field :class="[
                { 'md-valid': !errors.has('description') },
                { 'md-error': errors.has('description') }
              ]">
                <label>{{ $t('stdCols.name_ru') }}</label>
                <md-input v-model="description" type="text" data-vv-name="description" required
                  v-validate="modelValidations.description"></md-input>
              </md-field>

              <md-field :class="[
                { 'md-valid': !errors.has('description_en') },
                { 'md-error': errors.has('description_en') }
              ]">
                <label>{{ $t('stdCols.name_en') }}</label>
                <md-input v-model="description_en" type="text" data-vv-name="description_en" required
                  v-validate="modelValidations.description_en"></md-input>
              </md-field>

              <BaseDropdown :label="$t('label.inventory_event')" v-model="fk_inventory_type_event"
                data-vv-name="fk_inventory_type_event" :items="invTypeEvents" :valueField="'inventory_type_event_id'" />
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { mapActions } from 'vuex'
import { BaseDropdown } from '@/pages/Components'
import { onClose } from '@/mixins/onCloseMixin'
import messagesMixin from '@/mixins/messagesMixin'

export default {
  name: 'inventory-type-edit-form',
  mixins: [messagesMixin],

  data() {
    return {
      inventory_type_id: null,
      description: null,
      description_en: null,
      fk_inventory_type_event: null,

      modelValidations: {
        description: { required: true, min: 3 },
        description_en: { required: true, min: 3 },
      }
    }
  },

  props: {
    oper: String
  },

  components: {
    BaseDropdown
  },

  async mounted() {
    const { inventory_type_id = null } = this.$route.params
    this.inventory_type_id = inventory_type_id
    if (this.oper === 'upd' && inventory_type_id) {
      const theInvType = await this.loadList({ inventory_type_id })
      this.description = theInvType.description
      this.description_en = theInvType.description_en
      this.fk_inventory_type_event = theInvType.fk_inventory_type_event
    }
    this.$nextTick(async () => {
      await this.$validator.validateAll();
    });

    // Load list for the dropdown
    this.$store.dispatch('GET_INVTYPE_EVENT_DROPDOWN').then(() => {
    })
  },

  methods: {
    ...mapActions({
      loadList: 'ReferenceData/LOAD_INVENTORY_TYPE_BY_ID',
      addItem: 'ReferenceData/ADD_NEW_INVENTORY_TYPE',
      editItem: 'ReferenceData/UPDATE_INVENTORY_TYPE',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    onClose,

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = {
        description: this.description,
        description_en: this.description_en,
        fk_inventory_type_event: this.fk_inventory_type_event
      }

      const action = this.oper === 'add' ? this.addItem : this.editItem;
      const payload = this.oper === 'add' ? theItem : { id: this.inventory_type_id, theItem };
      let errDesc = '';
      let newId;
      try {
        const res = await action(payload);
        newId = res?.inventory_type_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error');
      }

      this.$nextTick(() => this.$validator.reset());
      this.onClose(); //        this.$emit('close');
      this.savedMessage(errDesc, this.$t('label.inventory_type'), this.description, this.description_en);
      this.highlightRow(newId);

    }
  },

  computed: {
    invTypeEvents() {
      return this.$store.state.Dropdowns.invtype_events;
    },
    saveBtnDisabled() {
      return !this.description || this.description == '' || !this.description_en || this.description_en == '' ||
        !this.fk_inventory_type_event
    }
  }
}
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>